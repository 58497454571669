import videos from "../assets/videos.mp4";
import Marquee from "react-fast-marquee";
import logo from "../assets/logo.png";
import { useState, useRef } from "react";
import {
  IconButton,
  Divider,
  Grid,
  Button,
  Typography,
  Card,
  TextField,
  CardContent,
  Avatar,
  Stack,
} from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import sommer from "../assets/sommer.jpg";
import linda from "../assets/linda.jpg";
import william from "../assets/william.jpg";
import team from "../assets/team.jpg";

import one from "../assets/sponsors/01.png";
import two from "../assets/sponsors/02.png";
import three from "../assets/sponsors/03.png";
import four from "../assets/sponsors/04.png";
import five from "../assets/sponsors/05.png";
import six from "../assets/sponsors/06.png";

export const Home = () => {
  let year = new Date().getFullYear();
  let sommerExp = year - 1996;
  let mattExp = year - 2008;

  const form = useRef();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);

  function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  async function submit(e) {
    e.preventDefault();
    setSending(true);
    const config = {
      SecureToken: "f9519587-2a41-4395-ba9e-68a2a6eabc61",
      To: "webform@tclegalteam.com",
      From: "webform@tclegalteam.com",
      Subject: "Message from web form",
      Body: `Name: ${name} <br> Phone: ${phone} <br> Email: ${email} <br> Message: ${message}`,
    };
    if (window.Email) {
      window.Email.send(config).then(() => setSent(true));
    }
    setSending(false);
  }

  return (
    <>
      <div className="hero">
        <video
          src={videos}
          muted
          autoPlay={"autoplay"}
          preload="auto"
          loop
          height="100%"
          width={"100%"}
          className="video"
        />

        <div className="header-text">
          <h1 style={{ fontSize: "2.5rem" }}>TC Legal Team</h1>
          <Typography variant="h6">
            Attorneys for Employers, Insurers, Third Party Administrators, and
            other businesses in Oregon and Washington.
          </Typography>
          <div style={{ marginTop: 30 }}>
            <Typography variant="h5">
              <TaskAltIcon sx={{ marginRight: 2, color: "white" }} />
              Oregon
            </Typography>{" "}
            <br />
            <Typography variant="h5">
              <TaskAltIcon sx={{ marginRight: 2, color: "white" }} />
              Washington
            </Typography>
          </div>
          <div style={{ marginTop: 30 }}>
            <Button variant="contained" component="a" href="#contact">
              CONTACT US
            </Button>
          </div>
        </div>
      </div>

      <div id="team" style={{ height: "100vh" }}>
        <div
          style={{ textAlign: "center", paddingTop: 100, paddingBottom: 100 }}
        >
          <Typography variant="h3">Meet our team</Typography>
        </div>

        <Grid
          container
          justifyContent={"center"}
          spacing={5}
          sx={{ padding: 3 }}
        >
          <Grid item lg={4} xs={12}>
            <img
              className="shadow"
              alt="sommer tolleson"
              src={sommer}
              width={"100%"}
              loading="lazy"
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <Typography variant="h4" gutterBottom color="primary">
              Sommer Tolleson
              <IconButton>
                <LinkedInIcon />
              </IconButton>
            </Typography>
            <Typography variant="caption" color="text.secondary">
              MANAGING PARTNER
            </Typography>
            <hr style={{ opacity: 0.3 }} />
            <p>
              Sommer Tolleson specializes in representing employers, insurers,
              and businesses in Oregon and Washington workers' compensation
              matters. Prior to earning her law degree, Sommer worked several
              years as a certified Oregon workers' compensation claims examiner.
              With over {sommerExp} years of experience in the field, she is a
              sought after expert providing continuing claims and legal
              education and updates. In addition to speaking on workers'
              compensation matters, she is a contributing editor for the
              Oregon Business & Industry annual Oregon Human Resources Manual
              publication.
            </p>

            <Button
              sx={{ textTransform: "none" }}
              size="small"
              variant="outlined"
              onClick={() =>
                window.location.assign("mailto:sommert@tclegalteam.com")
              }
            >
              Message Sommer
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"center"}
          spacing={5}
          sx={{ marginTop: 10, marginBottom: 10, padding: 3 }}
        >
          <Grid item lg={4} xs={12}>
            <img
              className="shadow"
              alt="linda conratt"
              src={linda}
              width={"100%"}
              loading="lazy"
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <Typography variant="h4" gutterBottom color="primary">
              Linda Conratt
              <IconButton
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/in/linda-conratt-780b6b40/",
                    "_blank",
                    "noreferrer"
                  )
                }
              >
                <LinkedInIcon />
              </IconButton>
            </Typography>
            <Typography variant="caption" color="text.secondary">
              MANAGING PARTNER
            </Typography>
            <hr style={{ opacity: 0.3 }} />
            <p>
              Linda Conratt represents employers, insurers, and third-party
              administrators involving workers’ compensation defense in Oregon
              and Washington. She speaks frequently on workers’ compensation
              topics. Active professionally, she served for many years as the
              Treasurer of the Executive Committee of the Workers’ Compensation
              Section of the Oregon State Bar. She started her workers'
              compensation journey as a claims examiner right out of college.
              She returned to Oregon in 1994 and rejoined the workers'
              compensation community as an attorney.
            </p>
            <Button
              sx={{ textTransform: "none" }}
              size="small"
              variant="outlined"
              onClick={() =>
                window.location.assign("mailto:lindac@tclegalteam.com")
              }
            >
              Message Linda
            </Button>
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent={"center"}
          spacing={5}
          sx={{ padding: 3, marginBottom: 10 }}
        >
          <Grid item lg={4} xs={12}>
            <img
              className="shadow"
              alt="bill replogle"
              src={william}
              width={"100%"}
              loading="lazy"
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <Typography variant="h4" gutterBottom color="primary">
              William (Bill) H. Replogle
            </Typography>
            <Typography variant="caption" color="text.secondary">
              MANAGING PARTNER
            </Typography>
            <hr style={{ opacity: 0.3 }} />
            <p>
              Bill joined the firm in 1994 as a partner. He passed the Uniform
              CPA examination in 1970, clerked for Judge Carlisle B. Roberts of
              the Oregon Tax Court 1971-1972, and was in-house counsel for
              Pacific Northwest Bell 1976-1979. Active professionally, he has
              served on committees of the Oregon State Bar and Multnomah Bar
              Association including Public Service, Bar Press Broadcasters,
              Judicial Screening, Professionalism, and Legal Ethics. He has been
              a speaker and presenter at various Bar and Oregon Law Institute
              educational seminars.
            </p>
            <Button
              sx={{ textTransform: "none" }}
              size="small"
              variant="outlined"
              onClick={() =>
                window.location.assign("mailto:billr@tclegalteam.com")
              }
            >
              Message Bill
            </Button>
          </Grid>
        </Grid>

     

        <div
          id="contact"
          style={{
            marginTop: 100,
            paddingBottom: 100,
            background: "white",
          }}
        >
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            spacing={5}
            sx={{ padding: 3, paddingTop: 10 }}
          >
            <Grid item lg={4} xs={12}>
              <div style={{ padding: 10, marginTop: 40 }}>
                <img
                  className="shadow"
                  style={{ borderRadius: "10px" }}
                  src={team}
                  width={"100%"}
                  height={"100%"}
                  loading="lazy"
                  alt="tc-legal-team"
                />
              </div>
            </Grid>

            <Grid item lg={4} xs={12}>
              <Card variant="outlined" sx={{ height: "100%" }}>
                <CardContent>
                  <div style={{ margin: "30px 0px" }}>
                    <h2>Have questions? We're here to help.</h2>
                    <p>
                      Call us at{" "}
                      <span style={{ fontWeight: "bold" }}>503-372-5020</span>{" "}
                      or message us here.
                    </p>
                  </div>
                  {sent ? (
                    <div style={{ margin: "1rem 0" }}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                      <p>
                        Message sent! We will be in contact as quickly as
                        possible.
                      </p>
                    </div>
                  ) : (
                    <form ref={form} onSubmit={submit}>
                      <Grid container spacing={2}>
                        <Grid item lg={12} xs={12}>
                          <TextField
                            fullWidth
                            required
                            label="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </Grid>
                        <Grid item lg={12} xs={12}>
                          <TextField
                            fullWidth
                            required
                            label="Phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </Grid>
                        <Grid item lg={12} xs={12}>
                          <TextField
                            fullWidth
                            required
                            label="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </Grid>
                        <Grid item lg={12} xs={12}>
                          <TextField
                            label="Write a message"
                            multiline
                            required
                            rows={4}
                            fullWidth
                            variant="outlined"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                          />
                        </Grid>
                        <Grid item lg={12} xs={12} sx={{ textAlign: "end" }}>
                          <Button
                            variant="contained"
                            disableElevation
                            type="submit"
                            disabled={sending}
                          >
                            Send
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>

        <div
          style={{
            height: "20vh",
            color: "#fff",
            padding: 20,
            display: "flex",
            alignItems: "center",
            background: "whitesmoke",
          }}
        >
          <Grid
            container
            justifyContent={"center"}
            sx={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item lg={12} xs={12}>
              <div id="sponsorship">
                <Marquee gradient={false}>
                  <img src={one} height={60} loading="lazy" />
                  <img src={two} height={60} loading="lazy" />
                  <img src={three} height={60} loading="lazy" />
                  <img src={four} height={60} loading="lazy" />
                  <img src={five} height={60} loading="lazy" />
                  <img src={six} height={60} loading="lazy" />
                </Marquee>
              </div>
            </Grid>
          </Grid>
        </div>

        <div style={{ margin: "3rem 0" }}>
          <Stack direction={"row"} justifyContent={"center"} spacing={5}>
            <a onClick={topFunction} style={{ cursor: "pointer" }}>
              Back to top
            </a>
            <br />
            <a href="#team">Our team</a>

            <br />
            <a href="#contact">Contact us</a>
          </Stack>
        </div>

        <Grid className="footer" container justifyContent={"center"}>
          <Grid item lg={8} xs={12} sx={{ textAlign: "center" }}>
            <img src={logo} style={{ height: "3.5rem" }} alt="logo" />
            <p>
              7190 Fir Loop, Suite 200
              <br />
              Tigard, Oregon 97223
              <br />
              Office: (503) 372-5020
            </p>

            <IconButton
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/company/tolleson-conratt-replogle-llp/",
                  "_blank",
                  "noreferrer"
                )
              }
            >
              <LinkedInIcon />
            </IconButton>
          </Grid>
          <Grid item lg={8} xs={12} sx={{ textAlign: "center" }}>
            <Typography variant="caption" color="text.secondary">
              Copyright © {year} TC Legal Team. All rights reserved.
            </Typography>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
