import { Home } from "./pages/Home";
import "./App.css";
import { MyRoutes } from "./MyRoutes";
import Navigation from "./component/Navigation";

function App() {
  return (
    <>
      <Navigation />
      <Home />
    </>
  );
}

export default App;
